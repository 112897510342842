import gql from "graphql-tag";

export const newsItemQuery = gql`
query post($slug: String!) {
  posts(filters:{ slug: { eq: $slug } }) {
    data {
      id
      attributes {
        slug
        image {
          data {
            id
            attributes {
              url
            }
          }
        }
        title
        published
        content
      }
    }
  }
}
`

export const massMediaItemQuery = gql`
query post($slug: String!) {
  smiONasPlural(filters:{ slug: { eq: $slug } }) {
    data {
      id
      attributes {
        slug
        image {
          data {
            id
            attributes {
              url
            }
          }
        }
        title
        published
        content
      }
    }
  }
}
`

// export const newsQuery = gql`
// query post {
//   posts {
//     data {
//       id
//       attributes {
//         slug
//         link
//         image {
//           data {
//             id
//             attributes {
//               url
//             }
//           }
//         }
//         title
//         published
//       }
//     }
//   }
// }
// `

export const mediaQuery = gql`
query smiONas {
  smiONasPlural(pagination: { limit: 1000 }) {
    data {
      attributes {
        slug
        title
        published
        image {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
`

export const newsQuery = gql`
query novosti {
  posts(pagination: { limit: 1000 }) {
    data {
      attributes {
        slug
        title
        published
        image {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
`

export const lastNewsQuery = gql`
query post($count: Int, $exclude:[String], $now:Date) {
  posts(
    sort:"published:desc",
    pagination:{limit:$count},
    filters:{slug:{notIn:$exclude},published:{lt:$now}}
  ) {
    data {
      id
      attributes {
        slug
        image {
          data {
            id
            attributes {
              url
            }
          }
        }
        title
        published
      }
    }
  }
}
`