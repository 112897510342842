<template>
  <NuxtLink class="news-card" :to="data.link">
    <div class="news-card__image">
      <img :src="url || defaultImage" :key="url" class="news-card__image-body"/>
    </div>
    <div class="news-card__content-wrapper sp-h-1 sp-b-1-3 td-sp-v-1">
      <p class="news-card__title sp-b-2 td-sp-b-0-3">{{ data.title }}</p>
      <p class="news-card__date">{{ data.published.split('-').reverse().join('.') }}</p>
    </div>
  </NuxtLink>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      config: {},
      url: undefined,
      defaultImage: '/images/news_dummy.jpg'
    }
  },
  beforeMount() {
    this.config = useRuntimeConfig()
  },
  mounted() {
    const imageSlug = this.data.image.data && (this.data.image.data.attributes?.url || this.data.image.data[0].attributes?.url)
    if (imageSlug) {
      this.url = this.config.public?.strapiUrl + (this.data.image.data.attributes?.url || this.data.image.data[0].attributes?.url)
    }
  }
}
</script>

<style lang="stylus">
.news-card
  border-radius 20px
  background-color main-color003-no-opacity
  display flex
  flex-direction column
  height 100%
  transition background-color .5s linear
  @media (hover: hover)
    &:hover
      background-color main-color007-no-opacity
      .news-card__image-body
        transform scale(1.2)
  @media (max-width tablet-upper-limit)
    border-radius 16px
  &__title
    font-size 22px
    line-height 28px
    font-weight 500
    color main-color085
    @media (max-width tablet-upper-limit)
      font-size 16px
      line-height 20px
  &__image
    border-radius 20px
    overflow hidden
    height 264px
    margin-bottom 40px
    z-index 2
    @media (max-width tablet-upper-limit)
      display none
    &-body
      width 100%
      height 100%
      object-fit cover
      transition transform 1s
      &__empty
        background-color #f8f8f8
        background url('/public/images/news_dummy.jpg')
        height 100%
        position relative
        // &::before
        //   content ''
        //   position absolute
        //   top -60%
        //   left -90%
        //   border-radius 50px
        //   transform rotateZ(45deg)
        //   width 600px
        //   height 600px
        //   background-color #f0f0f0
  &__content-wrapper
    flex-grow 1
    display flex
    align-items flex-start
    justify-content space-between
    flex-direction column
    color main-color05
  &__date
    font-size 18px
    line-height 24px
    @media (max-width tablet-upper-limit)
      font-size 12px
      line-height 16px

</style>
